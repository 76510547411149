import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './@core/components/login/login.component';
import { LoginGuard } from './@core/services/login.guard';

const routes: Routes = [
  // { path: 'overview', loadChildren: './dashboard/dashboard.module#DashboardModule' },
  { path: 'vehicles', loadChildren: './vehicles/vehicles.module#VehiclesModule',canActivate: [LoginGuard] },
  // { path: 'trips', loadChildren: './trips/trips.module#TripsModule' },
  // { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenanceModule' },
  // { path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsModule' },
  { path: '', redirectTo: 'login', pathMatch: 'full'  },
  { path: 'login', pathMatch: 'full', component: LoginComponent  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
