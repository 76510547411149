import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {CanActivate} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements  CanActivate {

  constructor(private router: Router) {}

  canActivate() {
    // this.router.navigate(['/login']);  
    console.log("AlwaysAuthGuard");
    return true;
  }
}
