import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  background = "";
  backgrounds = [];
  nightBackgrounds = [];

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });


  constructor() { 
    this.nightBackgrounds = [
      "/assets/images/login/night_pexels-joey-kyber-134643.jpg",
      "/assets/images/login/night_pexels-karol-d-night_409701.jpg",
      "/assets/images/login/night_pexels-mikechie-esparagoza-1600757.jpg",
      "/assets/images/login/night_pexels-pixabay-327345.jpg"
    ];

    this.backgrounds = [  
      "/assets/images/login/pexels-aleksejs-bergmanis-681335.jpg",
      "/assets/images/login/pexels-maureen-wahu-10154857.jpg",
      "/assets/images/login/pexels-pixabay-164634.jpg",
      "/assets/images/login/pexels-sergio-souza-2805321.jpg",
      "/assets/images/login/pexels-stephan-müller-753876.jpg",
      "/assets/images/login/pexels-tom-fisk-3245123.jpg",
      "/assets/images/login/pexels-zhang-kaiyv-1182334.jpg"
    ];

    if (new Date().getHours() > 17) {
      this.background = this.nightBackgrounds[this.getRandomInt(this.nightBackgrounds.length)];
    } else {
      this.background = this.backgrounds[this.getRandomInt(this.backgrounds.length)];
    }
  }

  ngOnInit() {
  }

  private getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
}
